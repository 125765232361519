<template>
  <div class="games-select-input-keyword">
    <div class="product-type-name">
        <p class="product-type-name-text">{{dataObject.title}}</p>
    </div>
    <div class="game-prop-search-keyword-input" >
        <input class="game-prop-search-keyword-input-box" v-model="searchKeyword" @change="inputHandler()" :placeholder="dataObject.placeholder"/>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
    name:"GamesSelectInputKeyword",
    props:{
        dataObject:{
            type:Object,
            default:()=>{}
        }
    },
    data(){
        return{
            searchKeyword:''
        }
    },
    methods:{
        ...mapMutations('buyList',['setTempSelectGamesList','resetTempSelectGamesList']),
        //输入操作
        inputHandler(){
            const tempSelectItem = {
                itemName: this.dataObject.aliasTitle,
                itemValue: this.searchKeyword
            };
            this.setTempSelectGamesList(tempSelectItem);
        },
        //清空store操作
        clearStoreHandler(){
            this.searchKeyword='';
            this.resetTempSelectGamesList([{aliasTitle:this.dataObject.aliasTitle}])
        }
    },
    beforeDestroy(){
        this.clearStoreHandler();
    }
}
</script>

<style lang="less" scoped>
.games-select-input-keyword{
    width: 1140px;
    margin-top: 30px;
    height: 36px;
    margin-left: 30px;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    
    .product-type-name{
        width: 100px;
        height: 30px;
        display: flex;
        align-items: center;
        cursor: default;
        .product-type-name-text{
            height: 21px;
            font-family: MicrosoftYaHei;
            font-size: 16px;
            color: #555555;
            line-height: 21px;
            text-align: left;
            font-style: normal;
        }
    }
    .game-prop-search-keyword-input{
        margin-left: 14px;
        width: 360px;
        height: 36px;
        background: #FFFFFF;
        
        .game-prop-search-keyword-input-box{
            width: 360px;
            height: 36px;
            border: 1px solid #E6E6E6;
            border-radius: 4px;
            outline: none;
            padding-left: 10px;
            padding-top: 4px;
            display: flex;
            &::-webkit-input-placeholder{ /* WebKit browsers */
                height: 21px;
                font-family: MicrosoftYaHei;
                font-size: 16px;
                color: #CCCCCC;
                line-height: 21px;
                text-align: left;
                font-style: normal;
            }
        }
    }
}
</style>