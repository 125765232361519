<template>
  <div class="games-disclaimers">
    <p class="games-disclaimers-text" v-html="disclaimersData"></p>
  </div>
</template>

<script>
export default {
    name:'GamesDisclaimers',
    data(){
        return {
            disclaimersData:`1.请勿私下联系卖家交易，谨防被骗。<br/>
                            2.根据国家法律规定，平台禁止未成年参与游戏交易。<br/>
                            3.所展示的商品信息由卖家提供，其真实性、准确性和合法性由信息发布人负责。`
        }
    }
}
</script>

<style lang="less" scoped>
.games-disclaimers{
    width: 1200px;
    .games-disclaimers-text{
        margin-top: 20px;
        margin-left: 20px;
        width: 1160px;
        height: 114px;
        font-family: MicrosoftYaHei;
        font-size: 16px;
        color: #222222;
        line-height: 38px;
        text-align: left;
        font-style: normal;
    }
}
</style>