<template>
  <div class="games-trading-notice">
    <div class="games-trading-process">
        <p class="games-trading-process-title">交易流程</p>
        <div class="games-trading-process-pic-box">
            <img class="games-trading-process-pic" src="https://newgamemarket.oss-cn-beijing.aliyuncs.com/tbh_pc_images/games-detail-trading-process.png" alt="交易流程图" >
        </div>
    </div>
    <div class="games-trading-notice-box" v-for="(item,index) in tradingNoticeData" :key="index">
        <p class="games-trading-notice-title">{{item.name}}</p>
        <p class="games-trading-notice-content" v-html="item.content"></p>
    </div>

  </div>
</template>

<script>
export default {
    name:'GamesTradingNotice',
    data(){
        return{
            tradingNoticeData:[
                {
                    name:'交易须知',
                    content:`1、买卖双方确认交易进入讨论组后，因个人原因取消交易需要支付此号成交代售费补偿其他两方。<br/>
                            2、双方同意【预定】账号进入讨论组后,任意方反悔取消交易需赔偿定金相同金额给另两方，各获赔50%.<br/>
                            3、中介交易买家验号前想取消交易，卖家同意的情况下支付5%手续费方可取消，中介费不退。<br/>
                            4、中介交易买家上号验证与卖家所述不相符可自行决定是否取消交易，如果取消需全额退款。<br/>
                            5、中介交易买家上号验证无误，因个人原因取消交易需要支付号价的5%补偿其他两方(按天榜号最低收费执行)。<br/>
                            6、换绑完成后无法取消交易，需双方协商。<br/>
                            7、交易前有任何疑问请提前询问客服，进入讨论组及视为认同交易规则。`
                },
                {
                    name:'买家须知',
                    content:`1、挂售账号截图里的流通金、点券、道具因日常消耗等会发生变化，均不作为账号详细参考。<br/>
                            2、若交易的账号发生卖家恶意找回平台会协助买家追回，追回无果平台协助报警处理并按所购包赔进行补偿。<br/>
                            3、账号增值服务属于游戏厂商收费（如QQ靓号），如果介意请验号前在群里告知客服，验号以后与代售平台无关且无法作为取消借口。<br/>
                            4、账号如发生冻结、异常、未成年模式等造成不能使用的情况，代售平台只能协助处理，有无法解冻的可能，与代售平台无关。并不得以此为理由要求退款(前提为卖家未告知买家可无责取消，如已告知情况买家已接受无法以此理由要求退款取消)<br/>
                            5、游戏内人脸属于腾讯保护机制，与代售平台无关并不得以此为理由要求退款。<br/>
                            6、买家想获得100%号价赔偿建议购买平台的找回包赔服务，只需额外支付包赔费用，即可享受不转手永久找回包赔。<br/>
                            7、实名问题不在包赔范围内，可以讲行协助处理。<br/>
                            【官网代售】已购包赔账号追回无果补偿号价100%，条件:只要账号购买后没转手,都无条件补偿,若买家谎称账号被找回或隐瞒账号已转手，按诈骗处理。`
                },
                {
                    name:'卖家须知',
                    content:`1、在天榜号出售账号，需要进行实名认证。若支持买家包赔服务，出售账号时需提供一个月内真实有效的网购订单截图，紧急联系人电话和签署电子转让合同。<br/>
                            2、如果您挂售的账号在多个平台销售，请保持寄售价格一致，否则天榜号有权将您账号下架；若同一账号多次下单后无理由不交付或长时间无法和您取得联系，平台会下架账号。<br/>
                            3、如挂售的账号存在找回记录，行骗记录等安全问题，天榜号有权中止交易和下架账号。<br/>
                            4、如您寄售的游戏账号已经出售或不愿进行销售，请在官网-卖家中心进行下架操作或联系客服告知情况进行下架，以免造成纠纷。<br/>
                            5、如买家需要咨询更多商品细节，烦请配合客服人员进行解答，如出现咨询后与官网描述不符时，买家有权申请全额退款。游戏账号交易具有特殊性，请尽量保持官网上架商品内容与实际账号内容一致，最终验证方式由买方上游戏账号验证为准。如出现与官网差异或与您咨询确认后存在差异等原因取消交易，平台不承担任何责任且无责取消。取消后请立刻上号确认并修改密码。<br/>
                            6、如发生买家上号验证账号期间出现争议，优先买卖双方协商处理，未出协商一致结果之前，您将争议账号售出，视为默认同意买方全额退款。<br/>
                            7、如因您个人原因无法正常交付账号，由买卖双方协商处理，未出协商一致结果之前，平台不接受任何理由取消订单。<br/>
                            8、如支持包赔且发生了恶意找回，若您本人找回的，天榜号有权向外提供相关资料，协助有关部门追回损失；若非您本人找回情形，您有义务配合天榜号协助处理，若您拒绝配合将按找回处理。`
                }
            ]
        }
    }
}
</script>

<style lang="less" scoped>
.games-trading-notice{
    width: 1200px;
    .games-trading-process{
        width: 1200px;
        .games-trading-process-title{
            margin-top: 20px;
            margin-left: 20px;
            height: 27px;
            font-family: MicrosoftYaHei, MicrosoftYaHei;
            font-weight: bold;
            font-size: 20px;
            color: #222222;
            line-height: 27px;
            text-align: left;
            font-style: normal;
        }
        .games-trading-process-pic-box{
            margin-top: 20px;
            margin-left: 40px;
            width: 1120px;
            height: 166px;
            // border: 1px dashed #888888;
            .games-trading-process-pic{
                width: 1120px;
                height: 166px;
            }
        }
    }
    .games-trading-notice-box{
        width: 1200px;
        .games-trading-notice-title{
            margin-top: 30px;
            margin-left: 20px;
            height: 27px;
            font-family: MicrosoftYaHei, MicrosoftYaHei;
            font-weight: bold;
            font-size: 20px;
            color: #222222;
            line-height: 27px;
            text-align: left;
            font-style: normal;
        }
        .games-trading-notice-content{
            margin-top: 20px;
            margin-left: 20px;
            width: 1160px;
            font-family: MicrosoftYaHei;
            font-size: 16px;
            color: #222222;
            line-height: 38px;
            text-align: left;
            font-style: normal;
        }
    }
}
</style>