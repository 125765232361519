<template>
  <div class="games-show-list">
    <!-- 商品列表展示菜单 -->
    <div class="games-show-list-menu">
        <ul>
            <li v-for="(item,index) in menuNameData" :key="index" @click="clickGamesShowMenuHandler(index)">
                <p class="menu-title">{{item}}</p>
            </li>
            <div class="menu-slider" :style="{left:sliderStyle,width:sliderStyleWidth}"></div>
        </ul>
    </div>
    <!-- 商品列表 -->
    <div class="games-show-info-list" v-if="filterResultForGamesList!=null&&filterResultForGamesList.data.length>0">
        <ul>
            <li v-for="(item,index) in filterResultForGamesList.data" :key="index">
                <div class="game-item-info-left">
                    <img class="game-item-info-pic" :src="item.productCoverUrl" alt="商品主图" >
                </div>
                <div class="game-item-info-right">
                    <div class="game-item-info-title">
                        <p class="game-item-info-title-text">【{{item.gameName}}】{{item.productIntroduction}}</p>
                    </div>
                    <div class="game-item-info-prop-box">
                        <p class="game-item-info-zone">游戏区服：{{item.gameZone}}</p>
                        <p class="game-item-info-product-type">商品标签：{{item.productCoverTag}}</p>
                        <p class="game-item-info-product-prop">卖家类型：{{item.partnerTag}}</p>
                        <p class="game-item-info-public-time">发布时间：{{item.createTime.slice(0,10)}}</p>
                    </div>
                    <div class="games-item-info-bottom">
                        <div class="games-item-info-left">
                            <p class="games-item-info-price">¥{{item.productPrice.toFixed(2)}}</p>
                        </div>
                        <div class="games-item-info-right">
                            <div class="games-item-info-collect">
                                <img src="/images/home/uncollect_icon.png" alt="收藏图标" class="games-item-info-collect-icon">
                                <p class="games-item-info-collect-text">收藏</p>
                            </div>
                            <div class="games-item-info-chat-btn">
                                <p class="games-item-info-chat-btn-text">聊一聊</p>
                            </div>
                            <div class="games-item-info-check-product-detail-btn">
                                <p class="games-item-info-check-product-detail-btn-text">查看详情</p>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <no-product-page v-else></no-product-page>
    <!-- 页码 -->
    <div  class="games-show-info-list-pagination" v-if="filterResultForGamesList!=null&&filterResultForGamesList.data.length>0">
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="filterData.pageNumber"
            :page-size="filterData.pageSize"
            layout="total, prev, pager, next"
            :total="filterResultForGamesList.totalCount">
        </el-pagination>
    </div>
  </div>
</template>

<script>
import {getFilterConditionalGamesList} from '@/services/buyList';
import NoProductPage from '../../components/no-product-page.vue';
import { mapState,mapMutations } from 'vuex';
export default {
    name:'GamesShowList',
    components:{
        NoProductPage
    },
    props:{
        currentSelectGameItem:{
            type:Object,
            default:null,
            required:true
        }
    },
    data(){
        return{
            currentMenuIndex:0,
            menuNameData:['综合排序','最新发布','按价格','按热度'],
            //totalCount:0, //总条数,
            filterData:{
                pageNumber:1,//当前页
                pageSize:20,//一页显示的数目 
            },
        }
    },
    methods:{
        ...mapMutations('buyList',['setFilterResultForGamesList']),
        clickGamesShowMenuHandler(index){
            this.currentMenuIndex = index;
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.filterData.pageNumber = val;
            //console.log(this.filterData)
            this.loadMoreFilterConditionalHandler();
        },
        //只有第一次加载时执行
        async loadFilterGamesList(){ 
            //console.log('this.filterData==='+JSON.stringify(this.filterData));
            try{
                this.filterData.gameId = this.currentSelectGameItem.id;
                console.log('filterData==='+JSON.stringify(this.filterData))
                const {data} = await getFilterConditionalGamesList(this.filterData);
                if(data.code==200){
                    this.setFilterResultForGamesList(data.data);
                }
            }catch(err){
                console.log('err==='+err);
            };
        },
        //高级过滤操作
        async loadMoreFilterConditionalHandler(){
            //this.filterData.gameId = this.currentSelectGameItem.id;
            let tempFilterData = {...this.filterData,filterData:this.tempSelectGamesList}
            console.log('tempFilterData=='+JSON.stringify(tempFilterData));
            try{
                const {data} = await getFilterConditionalGamesList(tempFilterData);
                if(data.code==200){
                    this.setFilterResultForGamesList(data.data)
                }
            }catch(err){}
        }
    },
    computed:{
        ...mapState({
            tempSelectGamesList:state=>state.buyList.tempSelectGamesList,
            filterResultForGamesList:state=>state.buyList.filterResultForGamesList
        }),
        //菜单滑块宽度
        sliderStyleWidth(){
            return this.menuNameData[this.currentMenuIndex].length*15+'px';
        },
        //菜单滑块距离左边起始点距离
        sliderStyle(){
            //0  50+15*menuNameData[0] 100+15*(menuNameData[0]+menuNameData[1])
            let tempLength = 0;
            this.menuNameData.forEach((element,index) => {
                if(index<this.currentMenuIndex){
                    tempLength += element.length;
                }
            });
            return this.currentMenuIndex*50+15*tempLength+'px';
        }
    },
    created(){
        console.log("games-show-list")
        this.loadFilterGamesList();
    }
}
</script>

<style lang="less" scoped>
.games-show-list{
    margin-top: 30px;
    width: 1200px;
    background: #FFFFFF;
    .games-show-list-menu{
        width: 1200px;
        height: 52px;
        background: #FFFFFF;
        box-shadow: 0px 1px 0px 0px rgba(0,0,0,0.03);
        margin-bottom: 1px;
        ul{
            margin-left: 30px;
            list-style-type: none;
            width: 1140px;
            height: 52px;
            position: relative;
            display: flex;
            align-items: center;
            // background-color: skyblue;
            li{
                margin-left: 50px;
                list-style: none;
                height: 52px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: default;
                .menu-title{
                    height: 20px;
                    font-family: MicrosoftYaHei;
                    font-size: 15px;
                    color: #555555;
                    line-height: 20px;
                    text-align: left;
                    font-style: normal;
                }
                &:first-child{
                    margin-left: 0px;
                }
                &:hover .menu-title{
                    color: #F02233;
                    font-weight: bold;
                }
            }
            .menu-slider{
                position: absolute;
                left: 0px;
                bottom: 0px;
                width: 60px;
                height: 2px;
                background: #F02233;
                transition: all 0.3s ease-in-out;
            }
        }
    }

    .games-show-info-list{
        width: 1200px;
        // height: 214px;
        background: #FFFFFF;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        ul{
            width: 1200px;
            list-style-type: none;
            background: #FFFFFF;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;

            li{
                width: 1200px;
                height: 214px;
                padding-top: 30px;
                padding-left: 30px;
                list-style: none;
                display: flex;
                justify-content: space-between;
                background: #FFFFFF;
                cursor: default;
                .game-item-info-left{
                    width: 275px;
                    height: 154px;
                    background: #F5F5F5;
                    border-radius: 6px;
                    .game-item-info-pic{
                        width: 275px;
                        height: 154px;
                        border-radius: 6px;
                    }
                }
                .game-item-info-right{
                    margin-right: 30px;
                    width: 845px;
                    height: 154px;
                    margin-left: 20px;
                    .game-item-info-title{
                        width: 845px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        line-clamp: 2;
                        word-break: break-all;
                        .game-item-info-title-text{
                            height: 52px;
                            font-family: MicrosoftYaHei;
                            font-size: 16px;
                            color: #131415;
                            line-height: 26px;
                            text-align: left;
                            font-style: normal;
                        }
                    }
                    .game-item-info-prop-box{
                        width: 845px;
                        margin-top: 16px;
                        display: flex;
                        .game-item-info-zone{
                            height: 17px;
                            font-family: MicrosoftYaHei;
                            font-size: 13px;
                            color: #888888;
                            line-height: 17px;
                            text-align: left;
                            font-style: normal;
                        }
                        .game-item-info-product-type{
                            margin-left: 40px;
                            height: 17px;
                            font-family: MicrosoftYaHei;
                            font-size: 13px;
                            color: #888888;
                            line-height: 17px;
                            text-align: left;
                            font-style: normal;
                        }
                        .game-item-info-product-prop{
                            margin-left: 40px;
                            height: 17px;
                            font-family: MicrosoftYaHei;
                            font-size: 13px;
                            color: #888888;
                            line-height: 17px;
                            text-align: left;
                            font-style: normal;
                        }
                        .game-item-info-public-time{
                            margin-left: 40px;
                            height: 17px;
                            font-family: MicrosoftYaHei;
                            font-size: 13px;
                            color: #888888;
                            line-height: 17px;
                            text-align: left;
                            font-style: normal;
                        }
                    }
                    .games-item-info-bottom{
                        width: 845px;
                        height: 40px;
                        margin-top: 30px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .games-item-info-left{
                            height: 40px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            .games-item-info-price{
                                height: 30px;
                                font-family: MicrosoftYaHei, MicrosoftYaHei;
                                font-weight: bold;
                                font-size: 22px;
                                color: #F02233;
                                line-height: 30px;
                                text-align: left;
                                font-style: normal;
                            }
                        }
                        .games-item-info-right{
                            height: 40px;
                            display: flex;
                            align-items: center;
                            .games-item-info-collect{
                                height: 40px;
                                display: flex;
                                align-items: center;
                                cursor: default;
                                .games-item-info-collect-icon{
                                    width: 40px;
                                    height: 40px;
                                }
                                .games-item-info-collect-text{
                                    height: 21px;
                                    font-family: MicrosoftYaHei;
                                    font-size: 16px;
                                    color: #999999;
                                    line-height: 21px;
                                    text-align: left;
                                    font-style: normal;
                                }
                            }
                            .games-item-info-chat-btn{
                                margin-left: 30px;
                                width: 140px;
                                height: 40px;
                                background: #FFF3F4;
                                border-radius: 6px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                cursor: default;
                                .games-item-info-chat-btn-text{
                                    height: 21px;
                                    font-family: MicrosoftYaHei;
                                    font-size: 16px;
                                    color: #F02233;
                                    line-height: 21px;
                                    text-align: right;
                                    font-style: normal;
                                }
                            }
                            .games-item-info-check-product-detail-btn{
                                margin-left: 20px;
                                width: 140px;
                                height: 40px;
                                background: #F02233;
                                border-radius: 6px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                cursor: default;
                                .games-item-info-check-product-detail-btn-text{
                                    height: 21px;
                                    font-family: MicrosoftYaHei;
                                    font-size: 16px;
                                    color: #FFFFFF;
                                    line-height: 21px;
                                    text-align: right;
                                    font-style: normal;
                                }
                            }
                        }
                    }
                }
                &:hover{
                    //outline: 2px solid rgba(0,0,0,0.07); //第1种方法，不太理想
                    // position: relative; //第2种方法
                    // z-index: 1; //第2种方法
                    transform: translateZ(0); //第3种方法
                    box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.07);
                }
            }
        }
    }

    .games-show-info-list-pagination{
        margin-top: 10px;
        height: 68px;
        width: 1200px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #F02233;   // 进行修改选中项背景和字体
    color: #fff;
}
/* 当鼠标划过页码时改变颜色 */
/deep/.el-pagination.is-background .el-pager li:hover {
    color:#F02233 ; /* 你想要设置的颜色 默认#409EFF */
}
</style>