<template>
  <div class="produc-price-range">
    <div class="product-type-name">
        <p class="product-type-name-text">{{dataObject.title}}</p>
    </div>
    <div class="price-input-box">
        <input class="price-input-low" type="number" v-model="priceRange.lowPrice" @change="inputPriceHandler()" :placeholder="dataObject.placeholder">
        <div class="separator-line">—</div>
        <input class="price-input-hight" type="number" v-model="priceRange.hightPrice" @change="inputPriceHandler()" :placeholder="dataObject.placeholder">
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
    name:"GamesSelectPriceRange",
    props:{
        dataObject:{
            type:Object,
            default:()=>{}
        }
    },
    data(){
        return{
            priceRange:{
                lowPrice:'',
                hightPrice:''
            }
            
        }
    },
    methods:{
        ...mapMutations('buyList',['setTempSelectGamesList','resetTempSelectGamesList']),
        inputPriceHandler(){
            // if(!this.priceRange.lowPrice&&!this.priceRange.hightPrice){
            //     this.priceRange.lowPrice = 0;
            //     this.priceRange.hightPrice = 0;
            // }
            const tempSelectItem = {
                itemName: this.dataObject.aliasTitle,
                itemValue: this.priceRange.lowPrice+","+this.priceRange.hightPrice
            };
            this.setTempSelectGamesList(tempSelectItem); 
        },
        //清空store操作
        clearStoreHandler(){
            this.priceRange.lowPrice='';
            this.priceRange.hightPrice='';
            this.resetTempSelectGamesList([{aliasTitle:this.dataObject.aliasTitle}])
        }
    },
    beforeDestroy(){
        this.clearStoreHandler();
    }
}
</script>

<style lang="less" scoped>
.produc-price-range{
    width: 1140px;
    background: #FFFFFF;
    margin-top: 20px;
    height: 36px;
    margin-left: 30px;
    display: flex;
    align-items: center;
    .product-type-name{
        width: 100px;
        height: 30px;
        display: flex;
        align-items: center;
        cursor: default;
        .product-type-name-text{
            height: 21px;
            font-family: MicrosoftYaHei;
            font-size: 16px;
            color: #555555;
            line-height: 21px;
            text-align: left;
            font-style: normal;
        }
    }
    .price-input-box{
        margin-left: 14px;
        width: 1026px;
        height: 36px;
        display: flex;
        align-items: center;
        .price-input-low{
            width: 150px;
            height: 36px;
            background: #FFFFFF;
            border-radius: 4px;
            border: 1px solid #E6E6E6;
            outline: none;
            padding-left: 10px;
            padding-top: 4px;
            &::placeholder{
                width: 16px;
                font-family: MicrosoftYaHei;
                font-size: 16px;
                color: #CCCCCC;
                line-height: 21px;
                text-align: left;
                font-style: normal;
            }
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button{
                -webkit-appearance:none;
            }
        }
        .separator-line{
            margin-left: 10px;
            width: 16px;
            height: 16px;
            font-family: MicrosoftYaHei;
            font-size: 16px;
            color: #000000;
            line-height: 16px;
            text-align: left;
            font-style: normal;
        }
        .price-input-hight{
            margin-left: 10px;
            width: 150px;
            height: 36px;
            background: #FFFFFF;
            border-radius: 4px;
            border: 1px solid #E6E6E6;
            outline: none;
            padding-left: 10px;
            padding-top: 4px;
            &::placeholder{
                width: 16px;
                font-family: MicrosoftYaHei;
                font-size: 16px;
                color: #CCCCCC;
                line-height: 21px;
                text-align: left;
                font-style: normal;
            }
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button{
                -webkit-appearance:none;
            }
        }
        input[type="number"]{
            appearance:none;
            -moz-appearance:none;
        }
    }
}
</style>