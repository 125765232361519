<template>
  <div class="games-select-advanced-options">
    <div class="product-type-name">
        <p class="product-type-name-text">{{dataObject.title}}</p>
    </div>
    <ul>
        <li v-for="(item,index) in dataObject.dataList" :key="index" @click="clickItemHandler(index)" :style="{border:isShowMask&&currentIndex===index?'1px solid #F02233':''}">
            <p class="product-prop-type-name" :style="{color:isShowMask&&currentIndex==index?'#F02233':''}">{{item.title}}</p>
            <img class="select-pull-down-icon" src="../../../../public/images/home/faq_right_icon.png" alt="下拉图标" >
            <!-- <div ref="mask" class="advanced-options-item-box-mask" @click="clickMaskHandler()"  :style="{display:isShowMask?'block':'none'}"> </div> -->
                <!-- 向上的三角形  -->
                <div  class="arrow-up" v-show="isShowMask&&currentIndex===index"></div>
                <div  v-show="isShowMask&&currentIndex===index" class="advanced-options-item-box">
                    <div class="advanced-options-item-box-top">
                        <p class="advanced-options-item-box-top-title">{{item.title}}</p>
                        <div class="advanced-options-item-box-top-select-all-btn" @click="clickSelectAllBtn(index)">
                            <img class="advanced-options-item-box-top-select-all" :src="selectAllFlag[index]?'/images/login/login_user_has_agreement_icon.png':'/images/login/login_user_not_agreement_icon.png'" alt="select_all" > 
                            <p class="advanced-options-item-box-top-select-all-text">全选</p>
                        </div>
                    </div>
                    <ul>
                        <li v-for="(subItem,subIndex) in item.subDataList" :key="subIndex" @click="clickCurrentItemHandler(subItem,index)" :style="getSubLiStyle(subItem, index)">
                            <p class="advanced-options-item-title" :style="{color:isSelected(subItem, index)?'#F02233':''}">{{subItem}}</p>
                        </li>
                    </ul>
                    <div class="advanced-options-item-box-bottom">
                        <div class="advanced-options-item-box-bottom-left" @click="resetSelection(index)">
                            <p class="advanced-options-item-box-bottom-reset">重置已选</p>
                        </div>
                        <div class="advanced-options-item-box-bottom-right">
                            <div class="advanced-options-item-box-bottom-cancel-btn" @click.stop="clickCancelBtnHandler(index)">
                                <p class="advanced-options-item-box-bottom-cancel-btn-text" >取消</p>
                            </div>
                            <div class="advanced-options-item-box-bottom-confirm-btn" @click.stop="clickConfirmBtn(index)">
                                <p class="advanced-options-item-box-bottom-confirm-btn-text">确定</p>
                            </div>
                        </div>
                    </div>
                </div>
            <!-- </div> -->
        </li>
    </ul>
  </div>
</template>

<script>
import {mapState,mapMutations} from 'vuex';
export default {
    name:"GamesSelectAdvancedOptions",
    props:{
        dataObject:{
            type:Object,
            default:()=>{}
        },
    },
    data(){
        return{
            currentIndex:-1,
            currentItemIndex:-1,
            currentAdvancedOptionsSelectList:[],
            selectAllFlag:[],
            // isShowMask:false
        }
    },
    methods:{
        ...mapMutations('buyList',['setTempSelectGamesList','resetTempSelectGamesList','setIsShowMask']),
        //点击选项
        clickItemHandler(index){
            this.currentIndex = index;
            //显示蒙层和弹出框
            this.setIsShowMask(true);
        },
        //点击选项框里的item
        clickCurrentItemHandler(item,index){
            // 初始化选择列表
            if (!this.currentAdvancedOptionsSelectList[index]) {
                this.$set(this.currentAdvancedOptionsSelectList, index, []); // 使用 Vue.set 确保响应式
            }
            const itemIndex = this.currentAdvancedOptionsSelectList[index].indexOf(item);
            if (itemIndex !== -1) {
                this.currentAdvancedOptionsSelectList[index].splice(itemIndex, 1); // 取消选中
            } else {
                this.currentAdvancedOptionsSelectList[index].push(item); // 选中
            }
        },
        //选中时的文字样式
        isSelected(item, index) {
            return this.currentAdvancedOptionsSelectList[index] && this.currentAdvancedOptionsSelectList[index].includes(item);
        },
        //选中时的Li样式
        getSubLiStyle(item, index) {
            return this.isSelected(item, index) ? 'background: #FFF3F4; border: 1px solid #F02233;' : '';
        },
        //重置已选
        resetSelection(index) {
            if(this.selectAllFlag[index]){
                this.$set(this.selectAllFlag,index,!this.selectAllFlag[index]);
            }
            this.$set(this.currentAdvancedOptionsSelectList, index, []); // 重置当前选项
        },
        //全选
        clickSelectAllBtn(index){
            this.$set(this.selectAllFlag,index,!this.selectAllFlag[index]);
            if(this.selectAllFlag[index]){
                this.$set(this.currentAdvancedOptionsSelectList,index,this.dataObject.dataList[index].subDataList);
            }else{
                this.resetSelection(index)
            }
        },
        //点击确认按钮
        clickConfirmBtn(index){
            // 实时更新样式
            const tempSelectItem = {
                itemName: this.dataObject.dataList[index].aliasTitle,
                itemValue: this.currentAdvancedOptionsSelectList[index]
            };
            this.setTempSelectGamesList(tempSelectItem);
            this.setIsShowMask(false);
            this.currentIndex = -1;
        },
        //点击取消按钮操作
        clickCancelBtnHandler(index){
            this.resetSelection(index);
            //隐藏蒙层和弹出框
            this.setIsShowMask(false);
            this.currentIndex = -1;
        },
        
    },
    beforeDestroy(){
        //如果用户本次有选择高级选项中的项目,销毁之前需要清空store中的数据
        if(this.currentAdvancedOptionsSelectList.length>0){
            this.resetTempSelectGamesList(this.dataObject.dataList);
        }
    },
    computed:{
        ...mapState({
            tempSelectGamesList:state=>state.buyList.tempSelectGamesList,
            isShowMask:state=>state.buyList.isShowMask
        }),

    }
}
</script>

<style lang="less" scoped>
.games-select-advanced-options{
    margin-left: 30px;
    margin-top: 20px;
    width: 1140px;
    display: flex;
    z-index: 999;

    .product-type-name{
        width: 100px;
        height: 30px;
        display: flex;
        align-items: center;
        cursor: default;
        .product-type-name-text{
            height: 21px;
            font-family: MicrosoftYaHei;
            font-size: 16px;
            color: #555555;
            line-height: 21px;
            text-align: left;
            font-style: normal;
        }
    }
    ul{
        list-style-type: none;
        width:1026px;
        display: flex;
        flex-wrap: wrap;
        li{
            margin-left: 20px;
            height: 36px;
            width: 200px;
            background: #FFFFFF;
            border-radius: 4px;
            border: 1px solid #E6E6E6;
            list-style: none;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: default;
            position: relative;
           
            .product-prop-type-name{
                margin-left: 10px;
                height: 21px;
                font-family: MicrosoftYaHei;
                font-size: 16px;
                color: #222222;
                line-height: 21px;
                text-align: left;
                font-style: normal;
            }
            .select-pull-down-icon{
                width: 11px;
                height: 6px;
                margin-right: 10px;
            }
            &:nth-child(n+5){
                margin-top: 14px;
            }
            &:nth-child(4n+1){
                margin-left: 14px;
            }
                .arrow-up {
                    z-index: 1000; 
                    position: absolute;
                    bottom: -16px;
                    left: 95px;
                    width: 0;
                    height: 0;
                    border-left: 8px solid transparent;
                    border-right: 8px solid transparent;
                    border-bottom: 10px solid white;
                }
                .advanced-options-item-box{
                    z-index: 999;
                    position: absolute;
                    left: -150px;
                    top: 140%;// 16/36=44% 跟arrow-up的bottom值有关
                    width: 550px;
                    background: #FFFFFF;
                    box-shadow: 0px 10px 40px 0px rgba(0,0,0,0.12);
                    border: 1px solid #F2F2F2;
                    display: flex;
                    flex-direction: column;
                    
                .advanced-options-item-box-top{
                    width: 550px;
                    height: 21px;
                    padding-left: 20px;
                    margin-top: 21px;
                    display: flex;
                    align-items: center;
                    position: relative;
                    
                    .advanced-options-item-box-top-title{
                        height: 21px;
                        font-family: MicrosoftYaHei, MicrosoftYaHei;
                        font-weight: bold;
                        font-size: 16px;
                        color: #222222;
                        line-height: 21px;
                        text-align: left;
                        font-style: normal;
                    }
                    .advanced-options-item-box-top-select-all-btn{
                        height: 21px;
                        display: flex;
                        align-items: center;
                        margin-left: 20px;
                        .advanced-options-item-box-top-select-all{
                            width: 16px;
                            height: 16px;
                        }
                        .advanced-options-item-box-top-select-all-text{
                            margin-left: 10px;
                            font-family: MicrosoftYaHei;
                            font-size: 16px;
                            color: #666666;
                            line-height: 21px;
                            text-align: left;
                            font-style: normal;
                        }
                    }
                    
                }
                ul{
                    width: 550px;
                    list-style-type: none;
                    padding: 0;
                    display: flex;
                    flex-wrap: wrap;
                    li{
                        margin-top: 20px;
                        margin-left: 20px;
                        list-style: none;
                        width: 157px;
                        height: 40px;
                        // background: #FFF3F4;
                        // border-radius: 4px;
                        // border: 1px solid #F02233;
                        background: #FFFFFF;
                        border-radius: 4px;
                        border: 1px solid #E6E6E6;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .advanced-options-item-title{
                            height: 21px;
                            font-family: MicrosoftYaHei;
                            font-size: 16px;
                            color: #222222;
                            // color: #F02233;
                            line-height: 21px;
                            text-align: right;
                            font-style: normal;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                        &:hover{
                            border: 1px solid #F02233;
                            .advanced-options-item-title{
                                color: #F02233;
                            }
                        }
                    }
                }
                .advanced-options-item-box-bottom{
                        margin-top: 30px;
                        width: 550px;
                        height: 40px;
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 20px;
                        .advanced-options-item-box-bottom-left{
                            width: 120px;
                            height: 40px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            .advanced-options-item-box-bottom-reset{
                                font-family: MicrosoftYaHei;
                                font-size: 16px;
                                color: #666666;
                                line-height: 21px;
                                text-align: left;
                                font-style: normal;
                            }
                        }
                        .advanced-options-item-box-bottom-right{
                            margin-right: 20px;
                            height: 40px;
                            display: flex;
                            .advanced-options-item-box-bottom-cancel-btn{
                                width: 120px;
                                height: 40px;
                                background: #FFFFFF;
                                border-radius: 6px;
                                border: 1px solid #E6E6E6;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                .advanced-options-item-box-bottom-cancel-btn-text{
                                    font-family: MicrosoftYaHei;
                                    font-size: 16px;
                                    color: #555555;
                                    line-height: 21px;
                                    text-align: right;
                                    font-style: normal;
                                }
                            }
                            .advanced-options-item-box-bottom-confirm-btn{
                                margin-left: 20px;
                                width: 120px;
                                height: 40px;
                                background: #F02233;
                                border-radius: 6px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                .advanced-options-item-box-bottom-confirm-btn-text{
                                    font-family: MicrosoftYaHei;
                                    font-size: 16px;
                                    color: #FFFFFF;
                                    line-height: 21px;
                                    text-align: right;
                                    font-style: normal;
                                }
                            }
                        }
                    }
                }
            
            
            
        }
    }
}
</style>