<template>
  <div class="games-detail">
    <div class="games-detail-top">
      <div class="games-detail-top-left">
        <img class="games-detail-main-pic" src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/24c35c98031147ec858cc428604afa72_mergeImage.png" alt="商品主图" >
      </div>
      <div class="games-detail-top-right">
        <div class="games-detail-title">
          <p class="games-detail-title-text">JQL21265 号 【34级】【4黄】【女主】，2个S级代理人：1命艾莲，格莉丝，1个S级音擎：精1嵌合编译器，2个S级邦布：精1鲨牙布，精1插头布，详情附图</p>
        </div>
        <div class="games-detail-props">
          <div class="games-detail-props-left">
            <p class="games-detail-props-left-top">商品编号：<span class="games-detail-props-value-text">JQL13344</span></p>
            <p class="games-detail-props-left-bottom">游戏区服：<span class="games-detail-props-value-text">绝区零-米哈游官服</span></p>
          </div>
          <div class="games-detail-props-right">
            <p class="games-detail-props-right-top">商品类型：<span class="games-detail-props-value-text">账号</span></p>
            <p class="games-detail-props-right-bottom">商品上架时间：<span class="games-detail-props-value-text">2024-07-06 22:27:30</span></p>
          </div>
        </div>
        <div class="games-detail-price-and-collect-and-btn">
          <div class="games-detail-price">
            <p class="games-detail-price-text">￥ 9999999元</p>
          </div>
          <div class="games-detail-collect-and-btn">
            <div class="games-detail-collect">
              <img class="games-detail-collect-pic" src="../../../../public/images//home/uncollect_icon.png" alt="收藏图标" >
              <p class="games-detail-collect-text">收藏</p>
            </div>
            <div class="games-detail-chat-btn">
              <p class="games-detail-chat-btn-text">咨询客服</p>
            </div>
            <div class="games-detail-buy-btn">
              <p class="games-detail-buy-btn-text">立即购买</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="games-detail-bottom">
      <!-- 商品菜单 -->
      <div class="games-detail-menu">
        <ul>
          <li @click="clickGamesDetailMenuHandler(0)">
            <p class="games-detail-menu-title" :style="currentMenuIndex===0?titleStyle:''">商品详情</p>
          </li>
          <li @click="clickGamesDetailMenuHandler(1)">
            <p class="games-detail-menu-title" :style="currentMenuIndex===1?titleStyle:''">交易须知</p>
          </li>
          <li @click="clickGamesDetailMenuHandler(2)">
            <p class="games-detail-menu-title" :style="currentMenuIndex===2?titleStyle:''">免责声明</p>
          </li>
          <div class="menu-slider" :style="{left:menuSliderLeft}"></div>
        </ul>
      </div>
      <!-- 商品详情 -->
      <div class="games-detail-content" v-if="currentMenuIndex===0">
        <div class="games-detail-props-content">
          <!-- 基本信息1 -->
          <div class="games-detail-props-base-info-box">
            <div class="games-detail-base-info">
              <p class="games-detail-base-info-title">基础信息</p>
            </div>
            <div class="games-detail-props-info">
              <ul id="baseInfoUl">
                <li>
                  <p class="games-detail-props-item">游戏名称：王者荣耀</p>
                </li>
                <li>
                  <p class="games-detail-props-item">交易类型：账号/成品号</p>
                </li>
                <li>
                  <p class="games-detail-props-item">二次实名：可改实名</p>
                </li>
                <li>
                  <p class="games-detail-props-item">二次实名：可改实名</p>
                </li>
                <li>
                  <p class="games-detail-props-item">二次实名：可改实名</p>
                </li>
                <!-- <li>
                  <p class="games-detail-props-item">二次实名：可改实名</p>
                </li> -->
              </ul>
            </div>
          </div>
          <!-- 基本信息2 -->
          <div class="games-detail-other-props-info" v-for="(item,index) in gamesPropsData" :key="index">
            <div class="games-detail-props-title">
              <p class="games-detail-props-title-text">{{item.name}}</p>
            </div>
            <div class="games-detail-props-value">
              <p class="games-detail-props-value-text">{{item.value}}</p>
            </div>
          </div>
        </div>
        <!-- 基本信息3 -->
        <div class="games-detail-screenshot-info">
          <ul>
            <li>
              <img class="games-detail-screenshot-pic" src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngee8cdaf7bff1a9bb5fe30cb342e36aca14437251c91a5f330fe282b00c683c49" alt="商品详情截图" >
            </li>
            <li>
              <img class="games-detail-screenshot-pic" src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng91025276f7237f7195bc8b258d4a4acd83bd210d57e17c24a904bdb0f1ef2b86" alt="商品详情截图" >
            </li>
          </ul>
        </div>
      </div>
      <!-- 商品详情 -->
      <games-trading-notice v-if="currentMenuIndex===1"></games-trading-notice>
      <games-disclaimers v-if="currentMenuIndex===2"></games-disclaimers>
    </div>
  </div>
</template>

<script>
import GamesTradingNotice from './games-trading-notice.vue';
import GamesDisclaimers from './games-desclaimers.vue';
export default {
  name:'GamesDetail',
  components:{
    GamesTradingNotice,
    GamesDisclaimers
  },
  data(){
    return{
      currentMenuIndex:0,
      gamesPropsData:[
        {
          name:"典藏皮肤",
          value:"全息碎影 / 皮肤名称示例"
        },
        {
          name:"稀有皮肤",
          value:"幽恒 / 至尊宝 / 大圣娶亲 / 岱宗 / 烈魂 / 飞衡 / 玄嵩 / 神奇女侠 / 幽恒 / 至尊宝 / 大圣娶亲 / 岱宗 / 烈魂 / 飞衡 / 玄嵩 / 神奇女侠 / 幽恒 / 至尊宝 / 大圣娶亲 / 岱宗 / 烈魂 / 飞衡 / 玄嵩 / 神奇女侠"
        }
      ]
    }
  },
  methods:{
    clickGamesDetailMenuHandler(index){
      console.log('当前索引：'+index)
      this.currentMenuIndex = index;
    },
    //当一行不够3个li的时候，补充li元素
    initLoadLiElementTag(){
      const list = document.getElementById('baseInfoUl');
      const items = list.getElementsByTagName('li');
      const count = items.length;
      const remainder = count % 3;
      // console.log('initLoadLiElementTag---'+list.offsetWidth) //1039
      // console.log('initLoadLiElementTag---'+list.clientWidth) //1039
      // 如果除以3有余数，则需要补充空的li元素
      if (remainder !== 0) {
        const itemsToAdd = 3 - remainder; // 计算需要补充的空元素数
        for (let i = 0; i < itemsToAdd; i++) {
          const emptyLi = document.createElement('li');
          emptyLi.textContent = ''; // 可以选择插入空内容
          // emptyLi.style.borderRight = '1px solid #ECECEC';
          emptyLi.style.width='calc(100%/3)';
          list.appendChild(emptyLi);
        }
      }
      // 为每个 li 添加右边框，排除每行的最后一个 li
      for (let i = 0; i < items.length; i++) {
        if ((i + 1) % 3 !== 0) { // 判断是否为每行的最后一项
          items[i].style.borderRight = '1px solid #ECECEC'; // 添加右边框
        }
      }
    }
  },
  computed:{
    titleStyle(){
      return 'color:#F02233;font-weight: bold;'
    },
    menuSliderLeft(){ // 0  60+50  120+100
      return this.currentMenuIndex*(50+60)+'px';
    }
  },
  mounted(){
    this.initLoadLiElementTag();
  }
}
</script>

<style lang="less" scoped>
@item-width: calc(100%/3);
.games-detail{
    width: 1200px;

    .games-detail-top{
      margin-top: 20px;
      width: 1200px;
      height: 320px;
      background: #FFFFFF;
      border-radius: 6px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .games-detail-top-left{
        margin-left: 20px;
        width: 500px;
        height: 280px;
        border-radius: 4px;
        .games-detail-main-pic{
          width: 500px;
          height: 280px;
        }
      }
      .games-detail-top-right{
        margin-right: 20px;
        width: 640px;
        height: 280px;
        display: flex;
        flex-direction: column;
        .games-detail-title{
          width: 640px;
          height: 52px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: default;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          line-clamp: 2;
          -webkit-line-clamp: 2;
          word-break: break-all;
          .games-detail-title-text{
            width: 640px;
            height: 52px;
            font-family: MicrosoftYaHei;
            font-size: 18px;
            color: #131415;
            line-height: 26px;
            text-align: left;
            font-style: normal;
          }
        }
        .games-detail-props{
          margin-top: 19px;
          width: 640px;
          height: 96px;
          display: flex;
          cursor: default;
          background-image: url(../../../../public/images/home/product_detail_props_bg.png);
          background-size: 640px 96px;
          // border: 1px dashed #888888;
          .games-detail-props-left{
            width: 320px;
            height: 96px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding-left: 21px;
            .games-detail-props-left-top{
              height: 17px;
              font-family: MicrosoftYaHei;
              font-size: 13px;
              color: #888888;
              line-height: 17px;
              text-align: left;
              font-style: normal;
            }
            .games-detail-props-left-bottom{
              margin-top: 20px;
              height: 17px;
              font-family: MicrosoftYaHei;
              font-size: 13px;
              color: #888888;
              line-height: 17px;
              text-align: left;
              font-style: normal;
            }
            .games-detail-props-value-text{
              height: 17px;
              font-family: MicrosoftYaHei;
              font-size: 13px;
              color: #222222;
              line-height: 17px;
              text-align: left;
              font-style: normal;
            }
          }
          .games-detail-props-right{
            width: 320px;
            height: 96px;
            display: flex;
            justify-content: center;
            padding-left: 21px;
            flex-direction: column;
            .games-detail-props-right-top{
              height: 17px;
              font-family: MicrosoftYaHei;
              font-size: 13px;
              color: #888888;
              line-height: 17px;
              text-align: left;
              font-style: normal;
            }
            .games-detail-props-right-bottom{
              margin-top: 20px;
              height: 17px;
              font-family: MicrosoftYaHei;
              font-size: 13px;
              color: #888888;
              line-height: 17px;
              text-align: left;
              font-style: normal;
            }
            .games-detail-props-value-text{
              height: 17px;
              font-family: MicrosoftYaHei;
              font-size: 13px;
              color: #222222;
              line-height: 17px;
              text-align: left;
              font-style: normal;
            }
          }
        }
        .games-detail-price-and-collect-and-btn{
          margin-top: 73px;
          width: 640px;
          height: 40px;
          display: flex;
          justify-content: space-between;
          .games-detail-price{
            height: 40px;
            display: flex;
            align-items: center;
            cursor: default;
            .games-detail-price-text{
              height: 30px;
              font-family: MicrosoftYaHei, MicrosoftYaHei;
              font-weight: bold;
              font-size: 22px;
              color: #F02233;
              line-height: 30px;
              text-align: left;
              font-style: normal;
            }
          }
          .games-detail-collect-and-btn{
            display: flex;
            .games-detail-collect{
              cursor: default;
              display: flex;
              height: 40px;
              align-items: center;
              .games-detail-collect-pic{
                width: 40px;
                height: 40px;
              }
              .games-detail-collect-text{
                height: 21px;
                font-family: MicrosoftYaHei;
                font-size: 16px;
                color: #999999;
                line-height: 21px;
                text-align: left;
                font-style: normal;
              }
            }
            .games-detail-chat-btn{
              margin-left: 30px;
              width: 140px;
              height: 40px;
              border-radius: 6px;
              border: 1px solid #FFE8EA;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: default;
              .games-detail-chat-btn-text{
                height: 21px;
                font-family: MicrosoftYaHei;
                font-size: 16px;
                color: #F02233;
                line-height: 21px;
                text-align: right;
                font-style: normal;
              }
            }
            .games-detail-buy-btn{
              margin-left: 20px;
              width: 140px;
              height: 40px;
              background: #F02233;
              border-radius: 6px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: default;
              .games-detail-buy-btn-text{
                height: 21px;
                font-family: MicrosoftYaHei;
                font-size: 16px;
                color: #FFFFFF;
                line-height: 21px;
                text-align: right;
                font-style: normal;
              }
            }
          }
          
        }
      }
    }

    .games-detail-bottom{
      margin-top: 20px;
      width: 1200px;
      background: #FFFFFF;
      padding-bottom: 30px;
      border-radius: 6px;
      .games-detail-menu{
        width: 1200px;
        height: 52px;
        background: #FFFFFF;
        box-shadow: 0px 1px 0px 0px rgba(0,0,0,0.03);
        border-radius: 6px 6px 0px 0px;
        margin-bottom: 1px;
        ul{
          margin-left: 30px;
          width: 1140px;
          height: 52px;
          list-style-type: none;
          position: relative;
          display: flex;
          align-items: center;
          li{
            margin-left: 50px;
            list-style: none;
            cursor: default;
            .games-detail-menu-title{
              height: 20px;
              font-family: MicrosoftYaHei;
              font-size: 15px;
              color: #555555;
              line-height: 20px;
              text-align: left;
              font-style: normal;
            }
            &:first-child{
              margin-left: 0px;
            }
            &:hover .games-detail-menu-title{
              color: #F02233;
              font-weight: bold;
            }
          }
          .menu-slider{
            position: absolute;
            left: 0px;
            bottom: 0px;
            width: 60px;
            height: 2px;
            background: #F02233;
            transition: all 0.3s ease-in-out;
          }
        }
      }

      .games-detail-content{
        width: 1200px;
        padding-top: 20px;
        background: #FFFFFF;
        .games-detail-props-content{
          margin-left: 20px;
          width: 1160px;
          // height: 267px;
          border-radius: 6px;
          border: 1px solid #ECECEC;
          .games-detail-props-base-info-box{
            width: 1160px;
            display: flex;
            border-bottom: 1px solid #ECECEC;
            .games-detail-base-info{
              width: 120px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-right: #ECECEC;
              cursor: default;
              .games-detail-base-info-title{
                height: 20px;
                font-family: MicrosoftYaHei;
                font-size: 15px;
                color: #666666;
                line-height: 20px;
                text-align: left;
                font-style: normal;
              }
            }
            .games-detail-props-info{
              width: 1039px;
              border-left:1px solid #ECECEC;
              box-sizing: border-box;
              ul{
                width: 1039px;
                list-style-type: none;
                display: flex;
                flex-wrap: wrap;
                box-sizing: border-box;
                padding: 0;
                cursor: default;
                li{
                  // width: 346px;
                  width: @item-width;
                  box-sizing: border-box;
                  height: 36px;
                  list-style: none;
                  border-right:1px solid #ECECEC;
                  display: flex;
                  align-items: center;
                  // background-color: pink;
                  .games-detail-props-item{
                    margin-left: 20px;
                    height: 20px;
                    font-family: MicrosoftYaHei;
                    font-size: 15px;
                    color: #222222;
                    line-height: 20px;
                    text-align: left;
                    font-style: normal;
                  }
                  &:nth-child(3n){
                    border-right: 0px solid #ECECEC;
                  }
                }
              }
            }
          }

          .games-detail-other-props-info{
            display: flex;
            border-bottom: 1px solid #ECECEC;
            cursor: default;
            .games-detail-props-title{
              width: 120px;
              display: flex;
              justify-content: center;
              align-items: center;
              .games-detail-props-title-text{
                height: 20px;
                font-family: MicrosoftYaHei;
                font-size: 15px;
                color: #666666;
                line-height: 20px;
                text-align: left;
                font-style: normal;
              }
            }
            .games-detail-props-value{
              width: 1039px;
              display: flex;
              align-items: center;
              border-left: 1px solid #ECECEC;
              padding: 9px 20px 10px 20px;
              .games-detail-props-value-text{
                font-family: MicrosoftYaHei;
                font-size: 15px;
                color: #222222;
                line-height: 30px;
                text-align: left;
                font-style: normal;
              }
            }
            &:last-child{
              border-bottom: none;
            }
          }
        }
        .games-detail-screenshot-info{
            width: 1160px;
            ul{
              list-style: none;
              padding: 0;
              li{  
                width: 1160px;
                height: 643px;
                margin: 20px 0 0 20px;
                .games-detail-screenshot-pic{
                  width: 1160px;
                  height: 643px;
                }
                &:first-child{
                  margin-top: 30px;
                }
              }
            }
          }
      }
    }
}
</style>