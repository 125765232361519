import request from "../../utils/request";

 //根据过滤条件，获取游戏信息
 export const getFilterConditionalGamesList = (data)=>{
    return  request({
         method:'POST',
         url:'/game/getFilterConditionalGamesList',
         data
     })
 }
