<template>
  <div class="no-product-page-box" :style="{height:boxHeight+'px'}">
    <img class="no-product-page-icon" :style="{width:imageStyle+'px',height:imageStyle+'px'}" src="/images/home/no_product_page_icon.png" alt="no_product_icon" >
    <p class="no-product-page-tips" :style="{marginTop:tipsMarginTop+'px'}">暂无商品</p>
  </div>
</template>

<script>
export default {
    name:"NoProductPage",
    props:{
      boxHeight:{
        type:Number,
        default:624
      },
      imageStyle:{
        type:Number,
        default:200
      },
      tipsMarginTop:{
        type:Number,
        default:40
      }
    }
}
</script>

<style lang="less" scoped>
.no-product-page-box{
    padding: 20px 20px 30px 20px;
    width: 1200px;
    height: 624px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .no-product-page-icon{
        width: 200px;
        height: 200px;
    }
    .no-product-page-tips{
        margin-top: 40px;
        height: 22px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #000000;
        line-height: 22px;
        text-align: right;
        font-style: normal;
    }
}
</style>