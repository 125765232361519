<template>
    <div class="product-type">
        <ul>
            <div class="product-type-name">
                <p class="product-type-name-text">{{dataObject.title}}</p>
            </div>
            <li v-for="(item,index) in dataObject.dataList" :key="index" @click="clickRadioItemHandler(item,index)" :style="currentIndex===index?liSelectedStyle:''"> 
                <p class="product-prop-name" :style="{color:currentIndex===index?'#F02233':''}">{{item}}</p>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapState,mapMutations } from 'vuex';
export default {
    name:'GamesPropsList',
    props:{
        dataObject:{
            type:Object,
            default:()=>{}
        }
    },
    data(){
        return{
            currentIndex:-1,
            tempSelectTitles:[], //统计临时选中的项目，方便组件销毁时好清空store中的数据,
        }
    },
    methods:{
        ...mapMutations('buyList',['setTempSelectGamesList','resetTempSelectGamesList']),
        
        // {
        //   type:'radio',
        //   title:'商品类型',
        //   aliasTitle:'productType',
        //   dataList:['游戏账号']
        // }

        //点击项
        clickRadioItemHandler(item,index){
            if(!this.tempSelectTitles||this.tempSelectTitles.length==0){
               this.tempSelectTitles.push({aliasTitle:this.dataObject.aliasTitle})
            }
            this.currentIndex = index;
            let tempSelectItem = {
                itemName: this.dataObject.aliasTitle,
                itemValue: item
            };
            this.setTempSelectGamesList(tempSelectItem)
        },
        //重置选项
        resetRadioAllSelectedItems(){
            this.currentIndex = -1;
            if(this.tempSelectTitles.length>0){
                this.resetTempSelectGamesList(this.tempSelectTitles)
            }
        }

    },
    computed:{
        liSelectedStyle(){
            return 'background: #FFF3F4;border-radius: 4px;';
        }
    },
    beforeDestroy(){
        if(this.tempSelectTitles.length>0){
            this.resetTempSelectGamesList(this.tempSelectTitles)
        }
    }
}
</script>

<style lang="less" scoped>
.product-type{
    margin-top: 20px;
    width: 1140px;
    margin-left: 30px;
    ul{
        width: 1140px;
        list-style-type: none;
        height: 30px;
        display: flex;
        .product-type-name{
            width: 100px;
            display: flex;
            align-items: center;
            cursor: default;
            .product-type-name-text{
                width: 80px;
                height: 21px;
                font-family: MicrosoftYaHei;
                font-size: 16px;
                color: #555555;
                line-height: 21px;
                text-align: left;
                font-style: normal;
            }
        }
        li{
            list-style: none;
            margin-left: 10px;
            height: 30px;
            display: flex;
            align-items: center;
            cursor: default;
            padding: 4px 14px 5px;
            .product-prop-name{
                height: 21px;
                font-family: MicrosoftYaHei;
                font-size: 16px;
                color: #222222;
                line-height: 21px;
                text-align: left;
                font-style: normal;
            }
            &:nth-child(2){
                margin-left: 14px;
            }
            &:hover{
                background: #FFF3F4;
                border-radius: 4px;
            }
            &:hover .product-prop-name{
                color:#F02233;
            }
        }
    }
}
</style>